// import Splide JS
import Splide from "@splidejs/splide";
// デフォルトのテーマ
import "@splidejs/splide/css";

// ----------------------------------------
// スライダー
// ----------------------------------------
export default function initSplide() {
  // ----------------------------------------
  // 駐車場経営スライドショー
  // ----------------------------------------

  function _initmanagementSplide() {
    const splideTargetManage = document.querySelector(".p-management-slider");
    if (!splideTargetManage) return;
    const Manage = new Splide(".p-management-slider", {
      cover: true,
      autoplay: true,
      pauseOnHover: false,
      interval: 5000, //次のスライドに行くまでの速さ
      speed: 500, //ページ送りアニメーションの速さ
      perPage: 3,
      perMove: 1,
      gap: "45px",
      arrows: false,
      // padding: { left: "160px", right: "160px" },
      type: "loop", //スライドをループさせる
      trimSpace: false,
      pagination: true,
      focus: 0,
      destroy:true,
      breakpoints: {
        1024: {
          perPage: 1,
          gap: "16px",
          destroy:false,
        },
      },
    });
    Manage.mount();
  }
  _initmanagementSplide();
  
  // ----------------------------------------
  // 記事
  // ----------------------------------------

  function _initArticleSplide() {
    const splideTargetArticle = document.querySelectorAll(".p-article-slider");
    if (!splideTargetArticle) return;

    splideTargetArticle.forEach((slider) => {
      new Splide(slider, {
        cover: true,
        autoplay: true,
        pauseOnHover: false,
        interval: 5000,
        speed: 500,
        perPage: 3,
        perMove: 1,
        gap: "60px",
        pagination: false,
        type: "loop", //スライドをループさせる
        trimSpace: false,
        focus: 0,
        pagination: true,
        breakpoints: {
          1024: {
            perPage: 1,
            gap: "60px",
          },
        },
      }).mount();
    });
  }
  _initArticleSplide();

  // ----------------------------------------
  // セミナー
  // ----------------------------------------

  function _initSeminarSplide() {
    const splideTargetSeminar = document.querySelectorAll(".p-seminar-slider");
    if (!splideTargetSeminar) return;

    splideTargetSeminar.forEach((slider) => {
      new Splide(slider, {
        cover: true,
        autoplay: true,
        pauseOnHover: false,
        interval: 5000,
        speed: 500,
        perPage: 4,
        perMove: 1,
        gap: "62px",
        type: "loop", //スライドをループさせる
        trimSpace: false,
        focus: 0,
        pagination: false,
        breakpoints: {
          1024: {
            perPage: 2,
            gap: "16px",
            arrows: false,
            pagination: true,
          },
        },
      }).mount();
    });
  }
  _initSeminarSplide();

  // ----------------------------------------
  // ホーム
  // ----------------------------------------

  function _initHomeSplide() {
    const splideTargetHome = document.querySelectorAll(".p-home-slider");
    if (!splideTargetHome) return;

    splideTargetHome.forEach((slider) => {
      new Splide(slider, {
        cover: true,
        autoplay: true,
        pauseOnHover: false,
        interval: 5000,
        speed: 500,
        perPage: 1,
        perMove: 1,
        gap: "60px",
        padding: { right: "320px",left: "320px" },
        type: "loop", //スライドをループさせる
        trimSpace: false,
        arrows: false,
        focus: 0,
        breakpoints: {
          1440: {
            perPage: 1,
            padding: { right: "180px",left: "180px" },
          },
          1280: {
            perPage: 1,
            padding: { right: "140px",left: "140px" },
          },
          1024: {
            perPage: 1,
            gap: "16px",
            padding: { right: "16px",left: "16px" },
          },
        },
      }).mount();
    });
  }
  _initHomeSplide();

}
export { initSplide };
