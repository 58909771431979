export default function initCommonScript() {
  // 375px以下はviewport固定
  switchViewport();
  window.addEventListener("resize", switchViewport, false);

  // ヘッダーの高さをCSS変数に設定
  setHeaderHeight();

  // スクロールでheader固定
  scrollHeaderFixed();
  window.addEventListener("scroll", scrollHeaderFixed);

  // ハッシュ付きリンク遷移時
  hashLinkSmoothScroll();

  // ドロワーメニュー
  initDrawer();

  // ページTOPに戻るボタン
  goTopBtn();

  // タブ切り替え
  initTabSwitch();

  // ヘッダー検索窓
  headerSearchWidget();

  // メガメニューマウスオーバーで背景をつける
  headerMouseover();
}

/**
 * 375px以下はviewport固定（375px以下のレスポンシブ対応不要）
 */
function switchViewport() {
  const viewport = document.querySelector('meta[name="viewport"]');
  const value = window.outerWidth > 375 ? "width=device-width,initial-scale=1" : "width=375";
  if (viewport.getAttribute("content") !== value) {
    viewport.setAttribute("content", value);
  }
}

/**
 * ヘッダーの高さをCSS変数に設定する関数
 * 要素のリサイズを検知して再取得します。
 */
function setHeaderHeight(cssVariableName = "--header-height-gutter") {
  const header = document.querySelector(".js-header");

  if (!header) return;

  // position: fixed されていたら値を更新するような処理にしたいので
  // この部分に処理追加予定。fixedがなくなれば値を0にする処理も必要かも。

  let headerH = header.clientHeight;
  document.documentElement.style.setProperty(cssVariableName, headerH + "px");

  // 要素のリサイズを監視して再取得
  const resizeObserver = new ResizeObserver((entries) => {
    for (const entry of entries) {
      // ヘッダーの高さを再設定
      headerH = entry.contentRect.height;
      document.documentElement.style.setProperty(cssVariableName, headerH + "px");
    }
  });
  resizeObserver.observe(header);
}

/**
 * scrollでheader固定
 */
function scrollHeaderFixed() {
  const fixedHeader = document.querySelector(".js-header-fixed");

  if (!fixedHeader) return;

  // 一定量スクロールしたら表示
  fixedHeader.classList.toggle("is-header-fixed", window.scrollY > 100);
}

/**
 * ハッシュリンククリック時smooth、 headerガター調整
 */
export function hashLinkSmoothScroll() {
  const anchorLinks = document.querySelectorAll('a[href*="#"]');

  anchorLinks.forEach((link) => {
    link.addEventListener("click", (e) => {
      // ターゲット取得して座標まで移動
      const targetElm = document.querySelector(link.hash);

      if (targetElm) {
        e.preventDefault();
        _scrollToTarget(targetElm);
      }
    });
  });

  // アンカー付きでページ遷移した場合
  const urlHash = location.hash;
  if (urlHash) {
    const targetElm = document.querySelector(urlHash);
    window.addEventListener("load", () => {
      _scrollToTarget(targetElm);
    });
  }

  // 引数のターゲットをもとに画面スクロールする関数
  function _scrollToTarget(targetElm, smooth = true) {
    if (targetElm) {
      const targetRect = targetElm.getBoundingClientRect().top;
      let fixGutter = document.querySelector(".js-header-scroll").clientHeight;
      console.log(fixGutter);
      // 例外処理（本プロジェクトのみの処理）
      // const groupHeader = document.querySelector(".p-group-header");
      // if (groupHeader) {
      //   fixGutter = groupHeader.clientHeight;
      // }

      const targetPos = targetRect + window.scrollY - fixGutter - 30;

      window.scrollTo({
        top: targetPos,
        behavior: smooth ? "smooth" : "auto",
      });
    }
  }
}

/**
 * ドロワーメニュー
 */
function initDrawer() {
  // ハンバーガー・ドロワー開閉処理
  (function () {
    const hamburger = document.querySelector(".js-hamburger");
    const body = document.querySelector("body");
    const closeBtn = document.querySelector(".js-drawer-close");
    if (!hamburger) return
    let isOpen = false;

    hamburger.addEventListener("click", () => {
      isOpen = !isOpen;

      body.classList.toggle("is-drawer-open", isOpen);
    });

    // 閉じるボタンの処理
    if (closeBtn) {
      closeBtn.addEventListener("click", () => {
        isOpen = !isOpen;
        body.classList.toggle("is-drawer-open", isOpen);
      });
    }

    // ページ内リンククリックでもドロワーを閉じる
    const drawerLinks = Array.from(document.querySelectorAll(".l-drawer a"));

    drawerLinks.forEach((link) => {
      link.addEventListener("click", () => {
        const url = new URL(link.href);
        const hash = url.hash.slice(1); // `#`を除去

        if (document.getElementById(hash)) {
          isOpen = !isOpen;
          body.classList.toggle("is-drawer-open", isOpen);
        }
      });
    });
  })();

  // ドロワーメニューアコーディオン
  (function () {
    const parentNav = document.querySelectorAll(".js-drawer-parent");
    if (parentNav) {
      parentNav.forEach((item) => {
        let isOpen = false;
        item.addEventListener("click", (e) => {
          isOpen = !isOpen;

          item.classList.toggle("is-child-open", isOpen);
        });
      });
    }
  })();
}

/**
 * ページTOPに戻るボタン
 */
function goTopBtn() {
  const goTop = document.getElementById("js-go-top");

  if (!goTop) return;

  goTop.addEventListener("click", function () {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });

  function showBtn() {
    // 一定量スクロールしたら表示
    if (window.pageYOffset > 100) {
      goTop.classList.add("is-show");
    } else {
      goTop.classList.remove("is-show");
    }

    // 特定位置で固定
    const fixTrigger = document.querySelector(".js-observer-to-top");
    if (fixTrigger) {
      let triggerRect = fixTrigger.getBoundingClientRect();
      let triggerGutter = 0;

      if (window.innerHeight >= triggerRect.top + triggerGutter) {
        goTop.classList.add("is-fixed");
      } else {
        goTop.classList.remove("is-fixed");
      }
    }
  }

  showBtn(); // 初回起動
  window.addEventListener("scroll", showBtn);
}

/**
 * トリガーとターゲットのclassを付け外しする関数
 */
function initTabSwitch() {
  const tabTriggers = document.querySelectorAll("[data-tab-target]");
  const tabPanel = document.querySelectorAll("[data-tab-panel]");

  tabTriggers.forEach((trigger) => {
    trigger.addEventListener("click", () => {
      // タブスイッチ
      tabTriggers.forEach((elm) => {
        elm.classList.remove("is-selected");
      });
      trigger.classList.add("is-selected");

      // タブパネル
      const target = trigger.dataset.tabTarget;
      tabPanel.forEach((elm) => {
        elm.classList.toggle("is-open", elm.dataset.tabPanel === target);
      });
    });
  });

  const switchWrappers = document.querySelectorAll("[data-switch-wrapper]");

  // 複数箇所で使用するためwrapper毎に処理する
  switchWrappers.forEach((switchWrapper) => {
    const switchPrefix = switchWrapper.dataset.switchWrapper;

    const triggerElms = switchWrapper.querySelectorAll(
      '[data-switch-trigger^="' + switchPrefix + '"]'
    );
    const targetElms = switchWrapper.querySelectorAll(
      '[data-switch-target^="' + switchPrefix + '"]'
    );

    triggerElms.forEach((trigger) => {
      const targetData = trigger.dataset.switchTrigger;

      // クリックで発火
      trigger.addEventListener("click", function (e) {
        triggerElms.forEach((trigger) => {
          trigger.classList.toggle("is-active", trigger.dataset.switchTrigger === targetData);
        });
        targetElms.forEach((target) => {
          target.classList.toggle("is-active", target.dataset.switchTarget === targetData);
        });
      });
    });
  });
}

/**
 * header検索窓
 */
function headerSearchWidget() {
  const searchForm = document.querySelector(".js-header-search");
  const searchBtn = document.querySelector(".js-header-search-btn");
  if (!searchForm || !searchBtn) return;

  searchForm.addEventListener("click", () => {
    searchForm.classList.remove("is-hide");
    setTimeout(() => {
      searchBtn.setAttribute("type", "submit");
    }, 500);
  });
}

/**
 * メガメニュークリック表示非表示切り替え
 */
function headerMouseover() {
  const mouseOver = document.querySelector(".l-header-gnav-item--has-megamenu");
  const mouseOverItem = document.querySelector(".js-header-scroll");
  
  if (!mouseOver || !mouseOverItem) return;
  // mouseOver.addEventListener("mouseover", function () {
  //   mouseOverItem.classList.add("l-header-is-active");
  // });
  // mouseOver.addEventListener("mouseout", function () {
  //   mouseOverItem.classList.remove("l-header-is-active");
  // });
  mouseOver.addEventListener("click", function () {
    mouseOver.classList.toggle("is-active");
  });
}
