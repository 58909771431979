// ============================================
// モジュール読み込み
// ============================================
import initCommonScript from './common';
import initSplide from './splide';
// import initTabScript from './tab-switch';

// import MicroModal from './microModal';
// import initHomeScript from './home';
// import getZipData from './zipCode';

// ============================================
// DOMコンテンツ読み込み後の処理
// ============================================
document.addEventListener('DOMContentLoaded', function () {
  // サイト共通処理
  initCommonScript();

  // スライドショー
  initSplide();

  // タブ切り替え
  // initTabScript();
  
});
